export default [
  {
    path: "home",
    label: "Home",
  },
  {
    path: "feature",
    label: "Features",
  },
  {
    path: "services",
    label: "Services",
  },
  /*{
    path: "pricing",
    label: "Pricing",
  },
  {
    path: "team-member",
    label: "Team Members",
  },
  {
    path: "testimonial",
    label: "Testimonial",
  },*/
];
