/** @jsx jsx */
import { jsx, Image } from "theme-ui";
import { Link } from "components/link";
import logo from "assets/logo.jpeg";

export default function Logo() {
  return (
    <Link
      path="/"
      sx={{
        variant: "links.logo",
      }}
    >
      <Image width={60} src={logo} alt="Cloudinfy Business Solutions LTD. logo" />
    </Link>
  );
}
